import React, { useState, useEffect } from "react";
import cx from "classnames";
import { PageLink } from "src/components/link";

import styles from "./nav.module.css";

interface AvailabilityNavProps {
  buildingSlug: string;
  unitCategories: any[];
  common: {
    showCommonLink: boolean;
    commonLink?: string;
  };
}
export const AvailabilityNav = ({
  buildingSlug,
  unitCategories,
  common
}: AvailabilityNavProps) => {
  const { showCommonLink, commonLink } = common;
  return (
    <div className={styles.availabilityNavContainer}>
      <ul className={styles.availabilityNav}>
        <li className={styles.availabilityNavItem}>
          <PageLink
            to={`/availability/${buildingSlug}/all`}
            className={cx(
              "sans sans--caps-11 medium",
              styles.availabilityNavLink
            )}
          >
            <span className={styles.availabilityNavLinkTitle}>All</span>
          </PageLink>
        </li>
        {unitCategories &&
          unitCategories.map(unitCategory => {
            const { title, slug, _key } = unitCategory;
            return (
              <li key={_key}>
                <PageLink
                  to={`/availability/${buildingSlug}/${slug.current}`}
                  className={cx(
                    "sans sans--caps-11 medium",
                    styles.availabilityNavLink
                  )}
                >
                  <span className={styles.availabilityNavLinkTitle}>
                    {title}
                  </span>
                </PageLink>
              </li>
            );
          })}
        {showCommonLink && (
          <li className={styles.commonListItem}>
            <PageLink
              to={`/availability/${buildingSlug}/co-living`}
              className={styles.commonLink}
            >
              <span
                className={cx(
                  "sans sans--caps-11 medium",
                  styles.availabilityNavLinkTitle
                )}
              >
                Co-Living
              </span>
            </PageLink>
          </li>
        )}
      </ul>
    </div>
  );
};
