import React from "react";
import { Router } from "@reach/router";

import Availability from "src/templates/availability";

const AvailabilityPage = ({
	location,
	pageContext,
}: {
	location: any;
	pageContext: {};
}) => {
	return (
		<Router primary={false}>
			<Availability
				location={location}
				path="/availability/:buildingSlug/:unitCategorySlug"
				pageContext={pageContext}
			/>
		</Router>
	);
};

export default AvailabilityPage;
