import React from "react";
import cx from "classnames";

import { splitUnitCategory } from "src/utils/splitUnitCategory";

import { ReactComponent as AllAvailableResidences } from "src/images/svg/all-available-residences.svg";

import styles from "./header.module.css";

interface AvailabilityHeaderProps {
  slug: string;
  unitCategory: {
    title: string;
  };
  headline: string;
}
export const AvailabilityHeader = ({
  slug,
  unitCategory,
  headline,
}: AvailabilityHeaderProps) => {
  const [serif, sans] =
    slug !== "all" ? splitUnitCategory(unitCategory.title) : (slug = "all");

  return (
    <div className={styles.header}>
      {slug === "all" ? (
        <>
          <h1
            className={cx("serif serif--60 lower hide-small", styles.headline)}
          >
            {headline}
          </h1>

          <AllAvailableResidences
            className={cx("hide-min-medium", styles.allAvailableResidences)}
          />
        </>
      ) : (
        <h1 className={styles.unitCategory}>
          <span className={cx("serif serif--variable-extra-large lower")}>
            {serif}
          </span>
          {sans && (
            <span className={cx("sans sans--variable-extra-large ml025")}>
              {sans}
            </span>
          )}
        </h1>
      )}
      {/* {slug !== "co-living" && (
        <div className={styles.disclaimerHeader}>
          *Pricing reflects net-effective Rents - 2-Months on #415 and 1-Month
          on additional apartments homes for 15-Month Lease.
        </div>
      )} */}
    </div>
  );
};
