import React from "react";
import cx from "classnames";
import { PageLink } from "src/components/link";
import { useToggleContact } from "src/context/siteContext";
import styles from "./leasing-menu.module.css";

export const LeasingMenu = ({
  buildingSlug = `the-agnes`,
  applicationUrl
}: {
  buildingSlug: string;
  applicationUrl: string;
}) => {
  const toggleContact = useToggleContact();
  return (
    <>
      <div className={styles.leasingMenu}>
        <ul>
          <li className={styles.menuItem}>
            <PageLink
              to={`/availability/${buildingSlug}/all`}
              className={cx("sans sans--caps-11 medium", styles.menuLink)}
            >
              Browse Floor Plans
            </PageLink>
          </li>
          <li className={styles.menuItem}>
            <button
              className={cx("sans sans--caps-11 medium", styles.menuLink)}
              onClick={toggleContact}
            >
              Contact
            </button>
          </li>
          <li className={styles.menuItem}>
            <a
              // href={applicationUrl}
              href={
                "https://ironstate.securecafe.com/onlineleasing/the-agnes/guestlogin.aspx"
              }
              target="_blank"
              rel="noopener noreferrer"
              className={cx("sans sans--caps-11 medium", styles.menuLink)}
            >
              Apply Now
            </a>
          </li>{" "}
          <li className={styles.menuItem}>
            <a
              // href={applicationUrl}
              href={
                "https://theagnescoliving.securecafe.com/onlineleasing/agnes-c/guestlogin.aspx"
              }
              target="_blank"
              rel="noopener noreferrer"
              className={cx("sans sans--caps-11 medium", styles.menuLink)}
            >
              Co-Living Apply Now
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
